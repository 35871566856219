<template>
  <div>
    <b-overlay :show="loading">
      <b-tabs
        active-nav-item-class="font-weight-bold text-uppercase text-default"
        active-tab-class="font-weight-bold text-success"
        content-class="mb-3"
      >
        <b-tab title="Listado  Seguimientos"> <template #title>
                                                <feather-icon icon="ListIcon" />
                                                <span>Listado  Seguimientos</span>
                                              </template>
          <filtros-listado-seguimientos
            report-type="teletutoria"
            @buscar="buscarListadoSeguimientos"
            @descargar="descargarSeguimiento"
          />
          <b-card>
            <b-card-body>
              <h4 class="card-title">
                Listado
              </h4>
              <div>
                <b-row>
                  {{ notificaciones,mensaje }}
                  <b-col
                          cols="4"
                    class="mt-2"
                  >
                    <div class="d-flex align-items-center mb-1 mb-md-0">
                      <label>Mostrar</label>
                      <b-form-select
                        id="perPageSelect"
                        v-model="perPage"
                        :options="pageOptions"
                        size="sm"
                        class="mx-50 col-md-3"
                        @change="onPerPageChange"
                      />
                      <label>filas por página</label>
                    </div>
                  </b-col>
                  <b-col
                          cols="4"
                          class="mt-2"
                  >
                    <div v-if="totalRows > 0">
                      <span class="text-muted">  Mostrando del {{ from }} al {{ to }} de {{ totalRows }} filas</span>
                    </div>
                  </b-col>

                  <b-col cols="4">
                    <b-pagination
                            v-model="page"
                            :total-rows="totalRows"
                            :per-page="perPage"
                            align="right"
                            class="mt-2 mr-1"
                            aria-controls="my-table"
                            @change="onPageChange"
                    />
                  </b-col>

                  <b-col cols="12">
                    <b-table
                      striped
                      hover
                      small
                      :per-page="perPage"
                      :items="listadoSeguimientos"
                      :fields="fieldsSeguimientos"
                      :tbody-tr-class="warningIndicator"
                      responsive
                      class="mb-0"
                      show-empty
                    >
                      <template #cell(opciones)="row">
                        <!-- Column: Action -->
                        <div class="text-nowrap">
                          <feather-icon
                            v-b-modal.modal-historial-alumno
                            v-b-tooltip.hover.bottom="'Historial Alumno'"
                            size="20"
                            icon="TrelloIcon"
                            class="mr-1 text-primary"
                            @click="historialAlumno(row.item.id_detalle_alumno)"
                          />
                          <feather-icon
                            v-b-modal.modal-historial-seguimientos
                            v-b-tooltip.hover.bottom="'Dejar Seguimiento'"
                            size="20"
                            icon="EditIcon"
                            class="mr-1 text-primary"
                            @click="listHistorialSeguimientos(row.item.id_detalle_alumno)"
                          />
                          <feather-icon
                            v-b-modal.modal-editar-info-alumno
                            v-b-tooltip.hover.bottom="'Actualizar info Alumno'"
                            size="20"
                            icon="UserPlusIcon"
                            class="mr-1 text-primary"
                            @click="actualizarAlumnoDesdeListadoSeguimientos(row.item.id_detalle_alumno)"
                          />
                          <feather-icon v-if="row.item.estado_ficha == false"
                            v-b-tooltip.hover.bottom="'Actualizar datos'"
                            size="20"
                            icon="CheckSquareIcon"
                            class="mr-1 text-primary"
                            @click="updateDataAlumno(row.item.id_detalle_alumno)"
                          />

                          <!--<feather-icon
                            :id="`status-${row.item.id}-send-acction`"
                            v-b-tooltip.hover.top="row.item.nameActionEstatus"
                            :icon="row.item.iconActionEstatus"
                            @click="confirmar(row.item)"
                          />-->
                        </div>
                      </template>
                      <template #empty="scope">
                        <div class="text-center">
                          No existen resultados
                        </div>
                      </template>
                    </b-table>
                  </b-col>

                </b-row>
                <b-row class="pl-1">


                </b-row>
              </div>
            </b-card-body>
          </b-card>
        </b-tab>
        <b-tab title="Seguimientos masivos">  <template #title>
                                                <feather-icon icon="TrelloIcon" />
                                                <span>Seguimientos masivos</span>
                                              </template>
          <filtros-seguimientos-masivos
            report-type="teletutoria"
            @buscar="buscarListadoSeguimientosMasivos"
            @descargar="descargarSeguimientoMasivo"
          />
          <b-card>
            <b-card-body>
              <h4>Listado Masivo</h4>
              <div>
                <b-row>
                  <b-col
                          cols="4"
                          class="offset-8"
                  >
                    <div
                            class="text-right"
                    >
                      <div class="text-right" v-b-tooltip.hover.v-primary="'Debe seleccionar alumno para habilitar'">
                      <b-button
                              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                              v-b-modal.modal-dejar-seguimientos-masivos
                              variant="outline-primary"
                              :disabled="bloquearCrearSeguimientoMasivo"
                              type="submit"
                              class="ml-1"
                              @click="readNuevoSeguimiento()"
                      >
                        <feather-icon
                                icon="UserPlusIcon"
                                class="mr-50"
                        />
                        <span class="align-middle">Dejar Seguimiento</span>

                      </b-button>
                      </div>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                          cols="4"
                    class="mt-2"
                  >
                    <div class="d-flex align-items-center mb-1 mb-md-0">
                      <label>Mostrar</label>
                      <b-form-select
                        id="perPageSelect"
                        v-model="perPageMasivo"
                        :options="pageOptionsMasivo"
                        size="sm"
                        class="mx-50 col-md-3"
                        @change="onPerPageChangeMasivo"
                      />
                      <label>filas por página</label>
                    </div>
                  </b-col>
                  <b-col
                          cols="4"
                          class="mt-2"
                  >
                    <div v-if="totalRowsMasivo > 0">
                      <span class="text-muted">  Mostrando del {{ fromMasivo }} al {{ toMasivo }} de {{ totalRowsMasivo }} filas</span>
                    </div>
                  </b-col>

                  <b-col cols="4">
                    <b-pagination
                            v-model="pageMasivo"
                            :total-rows="totalRowsMasivo"
                            :per-page="perPageMasivo"
                            align="right"
                            class="mt-2 mr-1"
                            aria-controls="my-table"
                            @change="onPageChangeMasivo"
                    />
                  </b-col>

                  <b-col cols="12">
                    <b-table
                      striped
                      hover
                      small
                      :per-page="perPageMasivo"
                      :items="listadoSeguimientosMasivo"
                      :fields="fieldsSeguimientoMasivo"
                      :tbody-tr-class="warningIndicator"
                      responsive
                      class="mb-0"
                      show-empty
                    >
                      <template #head(check)="data">

                        <div class="text-center">

                          <b-form-checkbox
                            v-model="allSelected"
                            @change="toggleAll"
                          />
                        </div>
                      </template>
                      <template #cell(check)="data">
                        <div class="text-center">
                          <b-form-checkbox
                            v-model="selectedIdDetalleAlumnos"
                            name="checkbox"
                            :value="data.item.id_detalle_alumno"
                          />
                        </div>
                      </template>
                      <template #empty="scope">
                        <div class="text-center">
                          No existen resultados
                        </div>
                      </template>
                    </b-table>
                  </b-col>

                </b-row>
                <b-row class="pl-1">

                </b-row>
              </div>
            </b-card-body>
          </b-card>
        </b-tab>

      </b-tabs>
    </b-overlay>

    <!--Inicio Modal dejar seguimiento-->

    <b-modal
      id="modal-historial-seguimientos"
      centered
      size="xl"
      title="Historial Seguimientos"
      ok-only
      ok-title="Cerrar"
    >
      <b-overlay :show="infoAlumnoLoading">
        <b-card>
          {{ getInfoAlumno }}
          <app-collapse accordion>
            <AppCollapsePersonalizado
              title="Información Alumno"
              icono="ListIcon"
              :is-visible="true"
            >
              <b-row>
                <b-col cols="6">
                  <b-form-group
                    label="RUT"
                    label-for="h-rut"
                    label-cols-md="4"
                  >
                    <b-form-input
                      id="h-rut"
                      v-model="infoAlumno.rut"
                      placeholder="Sin Información"
                      readonly
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6" />
                <b-col cols="6">
                  <b-form-group
                    label="Nombre"
                    label-for="h-nombre"
                    label-cols-md="4"
                  >
                    <b-form-input
                      id="h-nombre"
                      v-model="infoAlumno.nombres"
                      placeholder="Sin Información"
                      readonly
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    label="Apellidos"
                    label-for="h-apellidos"
                    label-cols-md="4"
                  >
                    <b-form-input
                      id="h-apellidos"
                      v-model="infoAlumno.apellidos"
                      placeholder="Sin Información"
                      readonly
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    label="Teléfono 1"
                    label-for="h-telefono-1"
                    label-cols-md="4"
                  >
                    <b-form-input
                      id="h-telefono-1"
                      v-model="infoAlumno.telefono1"
                      placeholder="Sin Información"
                      readonly
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    label="Teléfono 2"
                    label-for="h-telefono-2"
                    label-cols-md="4"
                  >
                    <b-form-input
                      id="h-telefono-2"
                      v-model="infoAlumno.telefono2"
                      placeholder="Sin Información"
                      readonly
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    label="Teléfono 3"
                    label-for="h-telefono-3"
                    label-cols-md="4"
                  >
                    <b-form-input
                      id="h-telefono-3"
                      v-model="infoAlumno.telefono3"
                      placeholder="Sin Información"
                      readonly
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    label="Correo 1"
                    label-for="h-correo1"
                    label-cols-md="4"
                  >
                    <b-form-input
                      id="h-correo1"
                      v-model="infoAlumno.correo1"
                      placeholder="Sin Información"
                      readonly
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    label="Correo 2"
                    label-for="h-correo2"
                    label-cols-md="4"
                  >
                    <b-form-input
                      id="h-correo2"
                      v-model="infoAlumno.correo2"
                      placeholder="Sin Información"
                      readonly
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    label="Correo 3"
                    label-for="h-correo3"
                    label-cols-md="4"
                  >
                    <b-form-input
                      id="h-correo3"
                      v-model="infoAlumno.correo3"
                      placeholder="Sin Información"
                      readonly
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </AppCollapsePersonalizado>
          </app-collapse>
        </b-card>
      </b-overlay>
      <!-- Tabla historial seguimientos con botón nuevo seguimiento-->
      <b-overlay :show="historialSeguimientosLoading">
        <b-card>
          <b-card-body>
            <h4 class="card-title">
              Historial Seguimientos
            </h4>
            <div>
              <b-row>
                <b-col
                  sm="12"
                  md="4"
                  class="my-1"
                >
                  <div class="d-flex align-items-center mb-1 mb-md-0">
                    <label>Mostrar</label>
                    <b-form-select
                      id="perPageSelect"
                      v-model="perPageHistorialSeguimientos"
                      :options="pageOptionsHistorialSeguimientos"
                      size="sm"
                      class="mx-50 col-md-2"
                      @change="onPerPageChangeHistorialSeguimientos"
                    />
                    <label>filas por página</label>
                  </div>
                </b-col>
                <b-col
                  sm="4"
                  md="8"
                  class="my-1"
                >
                  <div class="d-flex justify-content-end ">
                    <b-button
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      v-b-modal.modal-dejar-seguimientos
                      variant="outline-primary"
                      type="submit"
                      class="ml-1"
                      @click="readNuevoSeguimiento()"
                    >
                      <feather-icon
                        icon="UserPlusIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">Nuevo Seguimiento</span>

                    </b-button>
                  </div>
                </b-col>

                <b-col cols="12">
                  <b-table
                    striped
                    hover
                    small
                    :per-page="perPageHistorialSeguimientos"
                    :items="listadoHistorialSeguimientos"
                    :fields="fieldsHistorialSeguimientos"
                    responsive
                    class="mb-0"
                    show-empty
                  >
                    <template #cell(editar)="row">
                      <!-- Column: Action -->
                      <div class="text-nowrap">
                        <feather-icon
                          v-b-modal.modal-editar-seguimiento
                          v-b-tooltip.hover.bottom="'Editar Seguimiento'"
                          size="20"
                          icon="Edit2Icon"
                          class="mr-1 text-primary"
                          @click="editarSeguimientoForm(row.item)"
                        />
                      </div>
                    </template>
                    <template #empty="scope">
                      <div class="text-center">
                        No existen resultados
                      </div>
                    </template>
                  </b-table>
                </b-col>

              </b-row>
              <b-row class="pl-1">
                <b-col
                  cols="8"
                  class="mt-3"
                >
                  <div v-if="totalRowsHistorialSeguimientos > 0">
                    <span class="text-muted">  Mostrando del {{ fromHistorialSeguimientos }} al {{ toHistorialSeguimientos }} de {{ totalRowsHistorialSeguimientos }} filas</span>
                  </div>
                </b-col>

                <b-col cols="4">
                  <b-pagination
                    v-model="pageHistorialSeguimientos"
                    :total-rows="totalRowsHistorialSeguimientos"
                    :per-page="perPageHistorialSeguimientos"
                    align="right"
                    class="mt-3 mr-1"
                    aria-controls="my-table"
                    @change="onPageChangeHistorialSeguimientos"
                  />
                </b-col>

              </b-row>
            </div>
          </b-card-body>
        </b-card>
      </b-overlay>
    </b-modal>

    <!--Modal Formulario dejar seguimiento-->

    <b-modal
      id="modal-dejar-seguimientos"
      title="Crear Seguimiento"
      ok-title="Guardar"
      cancel-variant="outline-primary"
      @ok="validationFormCrearSeguimiento"
    >
      <validation-observer ref="simpleRules">
        <b-form>
          <b-form-group
            label="Selecione categoria 1"
            label-for="vue-select"
          >
            <v-select
              id="vue-select"
              v-model="selectedOptionCat1"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              placeholder="Debe seleccionar Cat 1 Primero"
              :options="optionCat1"
              @input="getOptionCat2"
            />
          </b-form-group>
          <b-form-group
            label="Selecione categoria 2"
            label-for="vue-select"
          >
            <b-overlay
              :show="readCat2Loading"
              rounded="lg"
              opacity="0.6"
            >
              <v-select
                id="vue-select"
                v-model="selectedOptionCat2"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                placeholder="Seleccione Cat 2"
                :options="optionCat2"
                :disabled="readCat2"
                @input="getOptionCat3"
              />
            </b-overlay>
          </b-form-group>
          <b-form-group
            label="Selecione categoria 3"
            label-for="vue-select"
          >
            <b-overlay
              :show="readCat3Loading"
              rounded="lg"
              opacity="0.6"
            >
              <v-select
                id="vue-select"
                v-model="selectedOptionCat3"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                placeholder="Seleccione Cat 3"
                :options="optionCat3"
                :disabled="readCat3"
                @input="getOptionCat4"
              />
            </b-overlay>
          </b-form-group>
          <b-form-group
            label="Selecione categoria 4"
            label-for="vue-select"
          >
            <validation-provider
              #default="{ errors }"
              name="Cat 4"
              rules="required"
            >
              <b-overlay
                :show="readCat4Loading"
                rounded="lg"
                opacity="0.6"
              >
                <v-select
                  id="vue-select"
                  v-model="selectedOptionCat4"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  placeholder="Seleccione Cat 4"
                  :disabled="readCat4"
                  :options="optionCat4"
                />
              </b-overlay>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Comentario"
            label-for="vue-select"
          >
            <validation-provider
              #default="{ errors }"
              name="Comentario"
              rules="required"
            >
              <b-form-textarea
                id="textarea-small"
                v-model="comentarioNuevoSeguimiento"
                size="sm"
                placeholder="Dejar Comentario"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
    </b-modal>

    <!--Modal Formulario dejar seguimientos masivos -->

    <b-modal
      id="modal-dejar-seguimientos-masivos"
      title="Crear Seguimiento Masivo"
      ok-title="Guardar"
      cancel-variant="outline-primary"
      @ok="validationFormCrearSeguimientoMasivo"
    >
      <validation-observer ref="simpleRules">
        <b-form>
          <b-form-group
            label="Selecione categoria 1"
            label-for="vue-select"
          >
            <v-select
              id="vue-select"
              v-model="selectedOptionCat1"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              placeholder="Debe seleccionar Cat 1 Primero"
              :options="optionCat1"
              @input="getOptionCat2"
            />
          </b-form-group>
          <b-form-group
            label="Selecione categoria 2"
            label-for="vue-select"
          >
            <b-overlay
              :show="readCat2Loading"
              rounded="lg"
              opacity="0.6"
            >
              <v-select
                id="vue-select"
                v-model="selectedOptionCat2"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                placeholder="Seleccione Cat 2"
                :options="optionCat2"
                :disabled="readCat2"
                @input="getOptionCat3"
              />
            </b-overlay>
          </b-form-group>

          <b-form-group
            label="Selecione categoria 3"
            label-for="vue-select"
          >
            <b-overlay
              :show="readCat3Loading"
              rounded="lg"
              opacity="0.6"
            >
              <v-select
                id="vue-select"
                v-model="selectedOptionCat3"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                placeholder="Seleccione Cat 3"
                :options="optionCat3"
                :disabled="readCat3"
                @input="getOptionCat4"
              />
            </b-overlay>
          </b-form-group>
          <b-form-group
            label="Selecione categoria 4"
            label-for="vue-select"
          >
            <validation-provider
              #default="{ errors }"
              name="Cat 4"
              rules="required"
            >
              <b-overlay
                :show="readCat4Loading"
                rounded="lg"
                opacity="0.6"
              >
                <v-select
                  id="vue-select"
                  v-model="selectedOptionCat4"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  placeholder="Seleccione Cat 4"
                  :disabled="readCat4"
                  :options="optionCat4"
                />

              </b-overlay>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Comentario"
            label-for="vue-select"
          >
            <validation-provider
              #default="{ errors }"
              name="Comentario"
              rules="required"
            >
              <b-form-textarea
                id="textarea-small"
                v-model="comentarioNuevoSeguimiento"
                size="sm"
                placeholder="Dejar Comentario"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
    </b-modal>

    <!--Modal Formulario editar in formación alumno-->

    <b-modal
      id="modal-editar-info-alumno"
      title="Editar Información Alumno"
      ok-title="Guardar"
      cancel-variant="outline-primary"
      @ok="validationFormEditarInformacionAlumno"
    >
      <b-overlay
        :show="infoAlumnoLoading"
        rounded="lg"
        opacity="0.6"
      >
        <validation-observer ref="simpleRules">
          <b-form>
            <b-row>
              <b-col md="6">
                <b-form-group
                  label="RUT"
                  label-for="rut"
                >
                  <b-form-input
                    id="rut"
                    v-model="infoAlumno.rut"
                    placeholder="Sin Información"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="6" />
              <b-col md="6">
                <b-form-group
                  label-for="nombre *"
                >
                  <label class="pr-1">Nombre</label><b-icon
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-tooltip.hover.top="'Al editar este campo se cambiara la contraseña del alumno'"
                    icon="info-circle"
                    scale="1.2"
                    class="text-primary"
                    variant="outline-primary"
                  />
                  <validation-provider
                    #default="{ errors }"
                    name="nombre"
                    rules="required"
                  >
                    <b-form-input
                      id="nombre"
                      v-model="infoAlumno.nombre"
                      placeholder="Sin Información"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label-for="apellidoPaterno"
                >
                  <label class="pr-1">Apellido Paterno</label><b-icon
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-tooltip.hover.top="'Al editar este campo se cambiara la contraseña del alumno'"
                    icon="info-circle"
                    scale="1.2"
                    class="text-primary"
                    variant="outline-primary"
                  />
                  <validation-provider
                    #default="{ errors }"
                    name="apellido paterno"
                    rules="required"
                  >
                    <b-form-input
                      id="apellidoPaterno"
                      v-model="infoAlumno.apellidoPaterno"
                      placeholder="Sin Información"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Segundo Nombre"
                  label-for="segundoNombre"
                >
                  <b-form-input
                    id="segundoNombre"
                    v-model="infoAlumno.segNombreAlumno"
                    placeholder="Sin Información"
                  />
                </b-form-group>
                <b-form-group
                  label="Apellido Materno"
                  label-for="apellidoMaterno"
                >
                  <b-form-input
                    id="apellidoMaterno"
                    v-model="infoAlumno.apellidoMaterno"
                    placeholder="Sin Información"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">

                <b-form-group
                  label-for="teléfono1"
                >
                  <label class="pr-1">Telefono 1</label><b-icon
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-tooltip.hover.top="'Este el último que se ha ingresado para este alumno'"
                    icon="info-circle"
                    scale="1.2"
                    class="text-primary"
                    variant="outline-primary"
                  />
                  <b-form-input
                    id="telefono1"
                    v-model="infoAlumno.telefono1"
                    placeholder="Sin Información"
                  />
                </b-form-group>
                <b-form-group
                  label="Teléfono 3"
                  label-for="telefono3"
                >
                  <b-form-input
                    id="telefono3"
                    v-model="infoAlumno.telefono3"
                    placeholder="Sin Información"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Teléfono 2"
                  label-for="telefono2"
                >
                  <b-form-input
                    id="telefono2"
                    v-model="infoAlumno.telefono2"
                    placeholder="Sin Información"
                  />
                </b-form-group>

                <b-form-group
                  label-for="correo1"
                >
                  <label class="pr-1">Correo 1</label><b-icon
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-tooltip.hover.top="'Este el último que se ha ingresado para este alumno'"
                    icon="info-circle"
                    scale="1.2"
                    class="text-primary"
                    variant="outline-success"
                  />
                  <b-form-input
                    id="correo1"
                    v-model="infoAlumno.correo1"
                    placeholder="Sin Información"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Correo 2"
                  label-for="correo2"
                >
                  <b-form-input
                    id="correo2"
                    v-model="infoAlumno.correo2"
                    placeholder="Sin Información"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Correo 3"
                  label-for="correo3"
                >
                  <b-form-input
                    id="correo3"
                    v-model="infoAlumno.correo3"
                    placeholder="Sin Información"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-overlay>
    </b-modal>

    <!--Modal Formulario editar seguimiento-->

    <b-modal
      id="modal-editar-seguimiento"
      title="Editar Seguimiento"
      ok-title="Guardar"
      cancel-variant="outline-primary"
      @ok="validationFormEditarSeguimiento"
    >
      <validation-observer ref="simpleRules">
        <b-form>
          <b-form-group
            label="Selecione categoria 1"
            label-for="vue-select"
          >
            <v-select
              id="vue-select"
              v-model="selectedEditarCat1"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="optionCat1"
              @input="getOptionCat2"
            />
          </b-form-group>
          <b-form-group
            label="Selecione categoria 2"
            label-for="vue-select"
          >
            <b-overlay
              :show="readCat2Loading"
              rounded="lg"
              opacity="0.6"
            >
              <v-select
                id="vue-select"
                v-model="selectedEditarCat2"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="optionCat2"
                :disabled="readCat2"
                @input="getOptionCat3"
              />
            </b-overlay>
          </b-form-group>
          <b-form-group
            label="Selecione categoria 3"
            label-for="vue-select"
          >
            <b-overlay
              :show="readCat3Loading"
              rounded="lg"
              opacity="0.6"
            >
              <v-select
                id="vue-select"
                v-model="selectedEditarCat3"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="optionCat3"
                :disabled="readCat3"
                @input="getOptionCat4"
              />
            </b-overlay>
          </b-form-group>
          <b-form-group
            label="Selecione categoria 4"
            label-for="vue-select"
          >
            <validation-provider
              #default="{ errors }"
              name="Cat 4"
              rules="required"
            >
              <b-overlay
                :show="readCat4Loading"
                rounded="lg"
                opacity="0.6"
              >
                <v-select
                  id="vue-select"
                  v-model="selectedEditarCat4"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :disabled="readCat4"
                  :options="optionCat4"
                />
              </b-overlay>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Comentario"
            label-for="vue-select"
          >
            <validation-provider
              #default="{ errors }"
              name="Comentario"
              rules="required"
            >
              <b-form-textarea
                id="textarea-small"
                v-model="comentarioEditarSeguimiento"
                size="sm"
                placeholder="Dejar Comentario"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
    </b-modal>

    <!--Inicio Modal informacion del alumno-->
    <b-modal
      id="modal-historial-alumno"
      centered
      size="xl"
      title="Historial Alumno"
      ok-only
      ok-title="Cerrar"
    >
      <b-overlay :show="infoAlumnoLoading">
        <b-card>
          <b-form @submit.prevent>
            <app-collapse accordion>
              <AppCollapsePersonalizado
                title="Información Alumno"
                icono="ListIcon"
                :is-visible="true"
              >
                <b-row>
                  <b-col cols="6">
                    <b-form-group
                      label="RUT"
                      label-for="h-rut"
                      label-cols-md="4"
                    >
                      <b-form-input
                        id="h-rut"
                        v-model="infoAlumno.rut"
                        placeholder="Sin Información"
                        readonly
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6" />
                  <b-col cols="6">
                    <b-form-group
                      label="Nombre"
                      label-for="h-nombre"
                      label-cols-md="4"
                    >
                      <b-form-input
                        id="h-nombre"
                        v-model="infoAlumno.nombres"
                        placeholder="Sin Información"
                        readonly
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group
                      label="Apellidos"
                      label-for="h-apellidos"
                      label-cols-md="4"
                    >
                      <b-form-input
                        id="h-apellidos"
                        v-model="infoAlumno.apellidos"
                        placeholder="Sin Información"
                        readonly
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group
                      label="Teléfono 1"
                      label-for="h-telefono-1"
                      label-cols-md="4"
                    >
                      <b-form-input
                        id="h-telefono-1"
                        v-model="infoAlumno.telefono1"
                        placeholder="Sin Información"
                        readonly
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group
                      label="Teléfono 2"
                      label-for="h-telefono-2"
                      label-cols-md="4"
                    >
                      <b-form-input
                        id="h-telefono-2"
                        v-model="infoAlumno.telefono2"
                        placeholder="Sin Información"
                        readonly
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group
                      label="Teléfono 3"
                      label-for="h-telefono-3"
                      label-cols-md="4"
                    >
                      <b-form-input
                        id="h-telefono-3"
                        v-model="infoAlumno.telefono3"
                        placeholder="Sin Información"
                        readonly
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group
                      label="Correo 1"
                      label-for="h-correo1"
                      label-cols-md="4"
                    >
                      <b-form-input
                        id="h-correo1"
                        v-model="infoAlumno.correo1"
                        placeholder="Sin Información"
                        readonly
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group
                      label="Correo 2"
                      label-for="h-correo2"
                      label-cols-md="4"
                    >
                      <b-form-input
                        id="h-correo2"
                        v-model="infoAlumno.correo2"
                        placeholder="Sin Información"
                        readonly
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group
                      label="Correo 3"
                      label-for="h-correo3"
                      label-cols-md="4"
                    >
                      <b-form-input
                        id="h-correo3"
                        v-model="infoAlumno.correo3"
                        placeholder="Sin Información"
                        readonly
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6" />
                  <b-col cols="6">
                    <div class="text-right pt-2">
                      <div class="d-flex justify-content-end ">
                        <b-button
                          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                          v-b-modal.modal-editar-info-alumno
                          variant="outline-primary"
                          type="button"
                          class="ml-1"
                        >
                          <feather-icon
                            icon="UserPlusIcon"
                            class="mr-50"
                          />
                          <span class="align-middle">Editar Información</span>

                        </b-button>
                      </div>
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        class="d-none"
                        variant="outline-primary"
                        type="submit"
                      >

                        <feather-icon
                          icon="RefreshCcwIcon"
                          class="mr-50"
                        />
                        <span class="align-middle">Limpiar</span>
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </AppCollapsePersonalizado>
            </app-collapse>
          </b-form>
        </b-card>
      </b-overlay>
      <b-overlay :show="historialAlumnoLoading">
        <b-card>
          <b-card-body>
            <h4 class="card-title">
              Historial Alumno
            </h4>
            <div>
              <b-row>
                <b-col
                  sm="4"
                  md="8"
                  class="my-1"
                />

                <b-col cols="12">
                  <b-table
                    striped
                    hover
                    small
                    :items="listadoHistorialAlumno"
                    :fields="fieldsHistorialAlumno"
                    responsive
                    class="mb-0"
                    show-empty
                  >
                    <template #cell(info_por_modulo)="row">
                      <!-- Column: Opciones -->
                      <div class="text-nowrap text-center">
                        <feather-icon
                          v-b-modal.modal-informacion-modulo
                          v-b-tooltip.hover.bottom="'Información por módulo'"
                          size="20"
                          icon="ClipboardIcon"
                          class="mr-1 text-primary"
                          @click="infoPorModulo(row.item)"
                        />
                      </div>
                    </template>
                    <template #empty="scope">
                      <div class="text-center">
                        No existen resultados
                      </div>
                    </template>
                  </b-table>
                </b-col>

              </b-row>
            </div>
          </b-card-body>
        </b-card>
      </b-overlay>
    </b-modal>

    <!-- Modal información modulo-->
    <b-modal
      id="modal-informacion-modulo"
      centered
      size="xl"
      title="Información Módulo"
      ok-only
      ok-title="Cerrar"
    >
      <b-card>
        <b-card-body>
          <h4 class="card-title">
            Información Módulo
          </h4>
          <div>
            <b-row>
              <b-col
                sm="4"
                md="8"
                class="my-1"
              />

              <b-col cols="12">
                <b-table
                  striped
                  hover
                  small
                  :items="listaInfoPorModulo"
                  :fields="fieldsInformacionModulo"
                  responsive
                  class="mb-0"
                  show-empty
                >
                  <template #empty="scope">
                    <div class="text-center">
                      No existen resultados
                    </div>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </div>
        </b-card-body>
      </b-card>

    </b-modal>

    <!--    <b-modal
            ref="modalExport"
            title="Información"
            ok-only
            ok-title="Cerrar"
        >
          <p class="my-4">
            <b>Procesando el reporte</b>! Recibirá un correo con el enlace para descargar el informe
          </p>
        </b-modal>-->
  </div>

</template>

<script>

import {
  BButton, BButtonToolbar, ButtonGroupPlugin, VBTooltip, BTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required, integer } from '@validations'
import es from 'vee-validate/dist/locale/es.json'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapsePersonalizado from '@/components/AppCollapsePersonalizado2.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import FiltrosListadoSeguimientos from '@/components/FiltrosListadoSeguimientos'
import FiltrosSeguimientosMasivos from '@/components/FiltrosSeguimientosMasivos'
import store from '@/store'
import flatPickr from 'vue-flatpickr-component'
import seguimientos from '@/router/modules/seguimientos'
import { mapGetters } from 'vuex'
export default {
  name: 'Seguimientos',
  components: {
    BButtonToolbar,
    ButtonGroupPlugin,
    BButton,
    BTooltip,
    flatPickr,
    vSelect,
    AppCollapse,
    AppCollapsePersonalizado,
    AppCollapseItem,
    ValidationProvider,
    ValidationObserver,
    'filtros-listado-seguimientos': FiltrosListadoSeguimientos,
    'filtros-seguimientos-masivos': FiltrosSeguimientosMasivos,

  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {

  },
  data() {
    return {
      perPage: 50,
      required,
      integer,
      perPageMasivo: 50,
      perPageHistorialSeguimientos: 50,
      perPageHistorialAlumnos: 50,
      pageOptions: [50, 100, 500, 1000],
      pageOptionsMasivo: [50, 100, 500, 1000],
      pageOptionsHistorialSeguimientos: [50, 100, 500, 1000],
      pageOptionsHistorialAlumnos: [50, 100, 500, 1000],
      totalRows: 1,
      totalRowsMasivo: 1,
      totalRowsHistorialSeguimientos: 1,
      totalRowsHistorialAlumnos: 1,
      currentPage: 1,
      currentPageMasivo: 1,
      currentPageHistorialSeguimientos: 1,
      currentPageHistorialAlumnos: 1,
      ver: false,
      fromHistorialSeguimientos: 1,
      fromHistorialAlumnos: 1,
      from: 1,
      fromMasivo: 1,
      to: 1,
      toMasivo: 1,
      toHistorialSeguimientos: 1,
      toHistorialAlumnos: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      pageHistorialSeguimientos: 1,
      page: 1,
      pageMasivo: 1,
      filterOn: [],
      listaSeguimientos: [],
      listaSeguimientosMasivo: [],
      listaHistorialSeguimientos: [],
      listaHistorialAlumnos: [],
      listaInfoPorModulo: [],
      opcionCat1: [],
      opcionCat2: [],
      opcionCat3: [],
      opcionCat4: [],
      selectedOptionCat1: null,
      selectedOptionCat2: null,
      selectedOptionCat3: null,
      selectedOptionCat4: null,
      readCat2: true,
      readCat3: true,
      readCat4: true,
      comentarioNuevoSeguimiento: '',
      id_detalle_alumno: 0,
      usuarioLogeadoGeneral: '',
      selectedEditarCat1: null,
      selectedEditarCat2: null,
      selectedEditarCat3: null,
      selectedEditarCat4: 0,
      idSeguimientos: 0,
      comentarioEditarSeguimiento: null,
      allSelected: false,
      selectedIdDetalleAlumnos: [],
      selectedEstadoGeneral: 'active',
      notificacion: '',
      filter: {
        cursoCompletado:'',
        selectedHolding: '',
        selectedEmpresa: '',
        selectedAplication: '',
        selectedNumeroficha: '',
        selectedCorreo: '',
        selectedFechaInicio: '',
        selectedFechaCierre: '',
        selectedRut: '',
        selectedNombre: '',
        selectedEstado: true,
        selectedMisAsignados: true,
      },
      filterMasivo: {
        cursoCompletado:'',
        selectedMisAsignados: true,
        selectedHolding: '',
        selectedEmpresa: '',
        numFicha: '',
        selectedFechaInicio: '',
        selectedFechaCierre: '',
      },
      infoAlumno: {
        idAlumno: '',
        nombre: '',
        segNombreAlumno: '',
        nombres: '',
        apellidos: '',
        apellidoPaterno: '',
        apellidoMaterno: '',
        rut: '',
        telefono1: '',
        idContactoAlumno1: '',
        idContactoAlumno2: '',
        idContactoAlumno3: '',
        telefono2: '',
        telefono3: '',
        correo1: '',
        correo2: '',
        correo3: '',
      },
      infoAlumno2: null,
      fieldsSeguimientos: [
        // { key: 'id_detalle_alumno', label: 'Id' },
        { key: 'empresa', label: 'Empresa' },
        { key: 'holding', label: 'Holding' },
        { key: 'plataforma', label: 'Plataforma' },
        { key: 'contact_asignado', label: 'Contact Asignado' },
        { key: 'modalidad', label: 'Modalidad' },
        { key: 'curso', label: 'Curso' },
        { key: 'nombre', label: 'Nombre' },
        { key: 'apellido', label: 'Apellido' },
        { key: 'rut_alumno', label: 'Rut' },
        { key: 'correo', label: 'Correo' },
        { key: 'fecha_inicio', label: 'Fecha Inicio' },
        { key: 'fecha_cierre', label: 'Fecha Cierre' },
        { key: 'ficha', label: 'Ficha' },
        { key: 'costo', label: 'Costo' },
        { key: 'nota_final', label: 'Nota Final' },
        { key: 'avance', label: 'Avance' },
        { key: 'fus', label: 'F.U.S.' },
        { key: 'indicador', label: 'Indicador' },
        { key: 'opciones', label: 'Opciones' },
        { key: 'cts', label: 'C.T.S.' },
      ],
      fieldsSeguimientoMasivo: [
        // { key: 'id_detalle_alumno', label: 'Id' },
        { key: 'empresa', label: 'Empresa' },
        { key: 'holding', label: 'Holding' },
        { key: 'plataforma', label: 'Plataforma' },
        { key: 'contact_asignado', label: 'Contact Asignado' },
        { key: 'modalidad', label: 'Modalidad' },
        { key: 'curso', label: 'Curso' },
        { key: 'nombre', label: 'Nombre' },
        { key: 'apellido', label: 'Apellido' },
        { key: 'rut_alumno', label: 'Rut' },
        { key: 'fecha_inicio', label: 'Fecha Inicio' },
        { key: 'fecha_cierre', label: 'Fecha Cierre' },
        { key: 'ficha', label: 'Ficha' },
        { key: 'costo', label: 'Costo' },
        { key: 'nota', label: 'Nota Final' },
        { key: 'avance', label: 'Avance' },
        { key: 'indicador', label: 'Indicador' },
        { key: 'check', label: '' },
      ],
      fieldsHistorialAlumno: [
        // { key: 'id_detalle_alumno', label: 'Id' },
        { key: 'curso', label: 'Cursos' },
        { key: 'comercial', label: 'Comercial' },
        { key: 'ficha', label: 'Ficha' },
        { key: 'nota_final', label: 'Nota Final' },
        { key: 'fecha_nota_final', label: 'Fecha Nota Final' },
        { key: 'avance_curso', label: 'Avance Curso' },
        { key: 'info_por_modulo', label: 'Info Por Módulo' },
        { key: 'tiempo_conexion', label: 'Tiempo Conexión' },
        { key: 'primer_acceso', label: 'Primer Acceso' },
        { key: 'ultimo_acceso', label: 'Último Acceso' },
      ],
      fieldsInformacionModulo: [
        // { key: 'id_detalle_alumno', label: 'Id' },
        { key: 'nota', label: 'Nota' },
        { key: 'fecha_realizada', label: 'Fecha Realizada' },
        { key: 'nombre_evaluacion', label: 'Nombre Evaluación' },
      ],
      fieldsHistorialSeguimientos: [
        // { key: 'id_detalle_alumno', label: 'Id' },
        { key: 'tutor', label: 'Tutor' },
        { key: 'codigo', label: 'Código' },
        { key: 'fechaHora', label: 'Fecha/Hora' },
        { key: 'categoria1.descripcion', label: 'Categoría 1' },
        { key: 'categoria2.descripcion', label: 'Categoría 2' },
        { key: 'categoria3.descripcion', label: 'Categoría 3' },
        { key: 'categoria4.descripcion', label: 'Categoría 4' },
        { key: 'comentario', label: 'Comentario' },
        { key: 'editar', label: 'Editar' },
      ],
    }
  },
  computed: {
    ...mapGetters({
      userData: 'auth/getUserLogged',
    }),
    mensaje() {
      let mensaje = this.$store.state.seguimientos.mensaje
      if (mensaje == 'Se actualizó la informacion del alumno.') {
        this.makeToast('success','Datos Alumno',mensaje)
        store.commit('seguimientos/setMensaje', '')
        this.getListadoSeguimientos();
      }
      else if (mensaje != ''){
        this.makeToast('warning','Datos Alumno','Hubo un error. No se pudo actualizar la información del alumno')
        store.commit('seguimientos/setMensaje', '')
      }

    },

    listadoHistorialAlumno() {
      this.totalRowsHistorialAlumnos = this.$store.state.seguimientos.rowsTotalHistorialAlumno
      this.listaHistorialAlumnos = this.$store.state.seguimientos.listaHistorialAlumnos
      this.currentPageHistorialAlumnos = this.$store.state.seguimientos.currentPageHistorialAlumno
      this.toHistorialAlumnos = this.$store.state.seguimientos.toHistorialAlumno
      this.fromHistorialAlumnos = this.$store.state.seguimientos.fromHistorialAlumno

      if (this.listaHistorialAlumnos !== undefined) {
        return this.listaHistorialAlumnos.map(item => ({
          curso: item.curso,
          comercial: item.comercial,
          ficha: item.ficha,
          nota_final: item.nota_final,
          fecha_nota_final: item.fecha_nota_final,
          avance_curso: item.avance_curso,
          info_por_modulo: item.informacion_modulo,
          tiempo_conexion: item.conexion_sence,
          primer_acceso: item.primer_acceso,
          ultimo_acceso: item.ultimo_acceso,
        }))
      }
    },
    bloquearCrearSeguimientoMasivo() {

      if (this.selectedIdDetalleAlumnos.length > 0) {
        return false
      }
      return true
    },
    getInfoAlumno() {
      // this.infoAlumnoLoading = false
      this.infoAlumno2 = this.$store.state.seguimientos.infoAlumno

      if (this.infoAlumno2 !== undefined) {
        if (this.infoAlumno2 !== null) {
          this.infoAlumno.idAlumno = this.infoAlumno2[0].id_alumno
          this.infoAlumno.nombres = `${this.infoAlumno2[0].nombre_alumno} ${this.infoAlumno2[0].seg_nombre_alumno}`
          this.infoAlumno.nombre = this.infoAlumno2[0].nombre_alumno
          this.infoAlumno.segNombreAlumno = this.infoAlumno2[0].seg_nombre_alumno
          this.infoAlumno.apellidoPaterno = this.infoAlumno2[0].apellido_paterno_alumno
          this.infoAlumno.apellidoMaterno = this.infoAlumno2[0].apellido_materno_alumno
          this.infoAlumno.apellidos = `${this.infoAlumno2[0].apellido_paterno_alumno} ${this.infoAlumno2[0].apellido_materno_alumno}`
          this.infoAlumno.rut = this.infoAlumno2[0].rut_alumno
          this.infoAlumno.idContactoAlumno1 = this.infoAlumno2[0].contacto_alumno[0] ? this.infoAlumno2[0].contacto_alumno[0].id_contacto_alumno : ''
          this.infoAlumno.idContactoAlumno2 = this.infoAlumno2[0].contacto_alumno[1] ? this.infoAlumno2[0].contacto_alumno[1].id_contacto_alumno : ''
          this.infoAlumno.idContactoAlumno3 = this.infoAlumno2[0].contacto_alumno[2] ? this.infoAlumno2[0].contacto_alumno[2].id_contacto_alumno : ''
          this.infoAlumno.telefono1 = this.infoAlumno2[0].contacto_alumno[0] ? this.infoAlumno2[0].contacto_alumno[0].telefono_contacto : ''
          this.infoAlumno.telefono2 = this.infoAlumno2[0].contacto_alumno[1] ? this.infoAlumno2[0].contacto_alumno[1].telefono_contacto : ''
          this.infoAlumno.telefono3 = this.infoAlumno2[0].contacto_alumno[2] ? this.infoAlumno2[0].contacto_alumno[2].telefono_contacto : ''
          this.infoAlumno.correo1 = this.infoAlumno2[0].contacto_alumno[0] ? this.infoAlumno2[0].contacto_alumno[0].correo_contacto : ''
          this.infoAlumno.correo2 = this.infoAlumno2[0].contacto_alumno[1] ? this.infoAlumno2[0].contacto_alumno[1].correo_contacto : ''
          this.infoAlumno.correo3 = this.infoAlumno2[0].contacto_alumno[2] ? this.infoAlumno2[0].contacto_alumno[2].correo_contacto : ''
        }
      }
      return ''
    },
    listadoHistorialSeguimientos() {
      this.totalRowsHistorialSeguimientos = this.$store.state.seguimientos.rowsTotalHistorialSeguimientos
      this.listaHistorialSeguimientos = this.$store.state.seguimientos.listaHistorialSeguimientos
      this.currentPageHistorialSeguimientos = this.$store.state.seguimientos.currentPageHistorialSeguimientos
      this.toHistorialSeguimientos = this.$store.state.seguimientos.toHistorialSeguimientos
      this.fromHistorialSeguimientos = this.$store.state.seguimientos.fromHistorialSeguimientos

      if (this.listaHistorialSeguimientos !== undefined) {
        return this.listaHistorialSeguimientos.map(item => ({
          tutor: item.contact_seguimiento,
          codigo: item.id_seguimiento,
          fechaHora: `${item.fecha} ${item.hora}`,
          categoria1: item.cat1,
          categoria2: item.cat2,
          categoria3: item.cat3,
          categoria4: item.cat4,
          comentario: item.comentario,
          editar: '...',
        }))
      }
    },

    readCat2Loading() {
      return this.$store.state.seguimientos.readCat2Loading
    },
    readCat3Loading() {
      return this.$store.state.seguimientos.readCat3Loading
    },
    readCat4Loading() {
      return this.$store.state.seguimientos.readCat4Loading
    },
    optionCat1() {
      this.opcionCat1 = this.$store.state.seguimientos.optionsCat1

      if (this.opcionCat1 !== undefined) {
        return this.opcionCat1.map(item => ({
          value: item.id,
          title: item.descripcion,
        }))
      }
    },
    optionCat2() {
      this.opcionCat2 = this.$store.state.seguimientos.optionsCat2
      if (this.selectedOptionCat1 !== null || this.selectedEditarCat1 !== null) {
        this.readCat2 = false
      }

      if (this.opcionCat2 !== undefined) {
        return this.opcionCat2.map(item => ({
          value: item.id,
          title: item.descripcion,
        }))
      }
    },
    optionCat3() {
      this.opcionCat3 = this.$store.state.seguimientos.optionsCat3
      if (this.selectedOptionCat2 !== null || this.selectedEditarCat2 !== null) {
        this.readCat3 = false
      }
      if (this.opcionCat3 !== undefined) {
        return this.opcionCat3.map(item => ({
          value: item.id,
          title: item.descripcion,
        }))
      }
    },
    optionCat4() {
      this.opcionCat4 = this.$store.state.seguimientos.optionsCat4
      if (this.selectedOptionCat3 !== null || this.selectedEditarCat3 !== null) {
        this.readCat4 = false
      }
      if (this.opcionCat4 !== undefined) {
        return this.opcionCat4.map(item => ({
          value: item.id,
          title: item.descripcion,
        }))
      }
    },
    listadoSeguimientos() {
      this.totalRows = this.$store.state.seguimientos.rowsTotal
      this.listaSeguimientos = this.$store.state.seguimientos.listaSeguimientos
      this.currentPage = this.$store.state.seguimientos.currentPage
      this.to = this.$store.state.seguimientos.to
      this.from = this.$store.state.seguimientos.from
      if (this.listaSeguimientos !== undefined) {
        return this.listaSeguimientos.map(item => ({
          id_detalle_alumno: item.id_detalle_alumno,
          empresa: item.empresa,
          holding: item.holding,
          plataforma: item.plataforma,
          contact_asignado: item.contact_asignado,
          modalidad: item.modalidad,
          curso: item.curso,
          nombre: item.nombre_alumno,
          apellido: item.apellido_alumno,
          rut_alumno: item.rut_alumno,
          correo: item.correo_alumno,
          fecha_inicio: item.fecha_inicio,
          fecha_cierre: item.fecha_termino,
          ficha: item.num_ficha,
          costo: item.costo,
          nota_final: item.evaluacion_final,
          avance: item.avance,
          fus: item.fus,
          indicador: item.indicador,
          opciones: '...',
          cts: item.cts,
          estado_ficha: item.estado_ficha,
        }))
      }
    },
    listadoSeguimientosMasivo() {
      this.totalRowsMasivo = this.$store.state.seguimientos.rowsTotalMasivo
      this.listaSeguimientosMasivo = this.$store.state.seguimientos.listaSeguimientosMasivo
      this.currentPageMasivo = this.$store.state.seguimientos.currentPageMasivo
      this.toMasivo = this.$store.state.seguimientos.toMasivo
      this.fromMasivo = this.$store.state.seguimientos.fromMasivo

      if (this.listaSeguimientosMasivo !== undefined) {
        return this.listaSeguimientosMasivo.map(item => ({
          id_detalle_alumno: item.id_detalle_alumno,
          empresa: item.empresa,
          holding: item.holding,
          plataforma: item.plataforma,
          contact_asignado: item.contact_asignado,
          modalidad: item.modalidad,
          curso: item.curso,
          nombre: item.nombre_alumno,
          apellido: item.apellido_alumno,
          rut_alumno: item.rut_alumno,
          fecha_inicio: item.fecha_inicio,
          fecha_cierre: item.fecha_termino,
          ficha: item.num_ficha,
          costo: item.costo,
          nota: item.evaluacion_final,
          avance: item.avance,
          indicador: item.indicador,
        }))
      }
    },
    loading() {
      return this.$store.state.seguimientos.loading
    },
    infoAlumnoLoading() {
      return this.$store.state.seguimientos.infoAlumnoLoading
    },
    historialSeguimientosLoading() {
      return this.$store.state.seguimientos.historialSeguimientosLoading
    },
    historialAlumnoLoading() {
      return this.$store.state.seguimientos.historialAlumnoLoading
    },
    notificaciones() {
      this.notificacion = this.$store.state.seguimientos.notificaciones
      if (this.notificacion.status === 'error') this.notificacion.status = 'danger'
      if (this.notificacion.titulo !== '') {
        this.makeToast(this.notificacion.status, this.notificacion.titulo, this.notificacion.mensaje)
        this.notificacion.titulo = ''
        if (this.$store.state.seguimientos.recargarListHistorialSeguimientos) {
          this.$store.commit('seguimientos/setRecargarListHistorialSeguimientos', false)
          this.listHistorialSeguimientos(this.id_detalle_alumno)
        }
        if (this.$store.state.seguimientos.recargarInfoAlumno) {
          this.$store.commit('seguimientos/setRecargarInfoAlumno', false)
          this.recargarInfoAlumno()
        }
        if (this.$store.state.seguimientos.recargarListSeguimientosMasivos) {
          this.$store.commit('seguimientos/setRecargarListSeguimientosMasivos', false)
          this.getListadoSeguimientosMasivo()
        }
        if (this.$store.state.seguimientos.recargarListSeguimientos) {
          this.$store.commit('seguimientos/setRecargarListSeguimientos', false)
          this.getListadoSeguimientos()
        }
      }
    },
  },
  watch: {

  },
  mounted() {
    this.filterMasivo.usuarioLogeadoMasivo = this.userData.id_usuario
    this.filter.usuarioLogeado = this.userData.id_usuario
    this.usuarioLogeadoGeneral = this.userData.id_usuario
    this.getListadoSeguimientos()
    this.getListadoSeguimientosMasivo()
    localize('es', es)
  },
  methods: {

    validationFormEditarInformacionAlumno(objeto) {

      objeto.preventDefault()
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          //alert('form submitted!')

          this.editarInformacionAlumno()
          this.$bvModal.hide('modal-editar-info-alumno')
        }
      })
    },
    validationFormCrearSeguimientoMasivo(objeto) {

      objeto.preventDefault()
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          //alert('form submitted!')

          this.nuevoSeguimientoMasivo()
          this.$bvModal.hide('modal-dejar-seguimientos-masivos')
        }
      })
    },
    validationFormEditarSeguimiento(objeto) {

      objeto.preventDefault()
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          //alert('form submitted!')

          this.editarSeguimiento()
          this.$bvModal.hide('modal-editar-seguimiento')
        }
      })
    },
    validationFormCrearSeguimiento(objeto) {

      objeto.preventDefault()
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          //alert('form submitted!')

          this.nuevoSeguimiento()
          this.$bvModal.hide('modal-dejar-seguimientos')
        }
      })
    },
    editarInformacionAlumno() {

      const editarInfoAlumno = {
        id_alumno: this.infoAlumno.idAlumno,
        id_detalle_alumno: this.id_detalle_alumno,
        nombre_alumno: this.infoAlumno.nombre,
        seg_nombre_alumno: this.infoAlumno.segNombreAlumno,
        apellido_paterno_alumno: this.infoAlumno.apellidoPaterno,
        apellido_materno_alumno: this.infoAlumno.apellidoMaterno,
        rut_alumno: this.infoAlumno.rut,
        contacto_alumno: [{
          id_contacto_alumno: this.infoAlumno.idContactoAlumno1,
          correo_contacto: this.infoAlumno.correo1,
          telefono_contacto: this.infoAlumno.telefono1,
          id_alumno: this.infoAlumno.idAlumno,
        }, {
          id_contacto_alumno: this.infoAlumno.idContactoAlumno2,
          correo_contacto: this.infoAlumno.correo2,
          telefono_contacto: this.infoAlumno.telefono2,
          id_alumno: this.infoAlumno.idAlumno,
        }, {
          id_contacto_alumno: this.infoAlumno.idContactoAlumno3,
          correo_contacto: this.infoAlumno.correo3,
          telefono_contacto: this.infoAlumno.telefono3,
          id_alumno: this.infoAlumno.idAlumno,
        }],
      }
      this.$store.dispatch('seguimientos/editarInfoAlumno', editarInfoAlumno)
    },
    recargarInfoAlumno() {
      this.$store.dispatch('seguimientos/getInfoAlumno', this.id_detalle_alumno)
    },
    historialAlumno(item) {
      this.id_detalle_alumno = item
      this.$store.dispatch('seguimientos/getInfoAlumno', this.id_detalle_alumno)
      this.$store.dispatch('seguimientos/getListadoHistorialAlumno', this.id_detalle_alumno)
    },
    infoPorModulo(item) {
      this.listaInfoPorModulo = item.info_por_modulo
    },
    actualizarAlumnoDesdeListadoSeguimientos(item) {
      // this.infoAlumnoLoading = true
      this.id_detalle_alumno = item
      this.$store.dispatch('seguimientos/getInfoAlumno', this.id_detalle_alumno)
    },
    updateDataAlumno(idDetalleAlumno) {
      let filterData = {
        'id_detalle_alumno': idDetalleAlumno,
      }
      this.$store.dispatch('seguimientos/updateDataAlumnoRT', filterData)
    },
    readNuevoSeguimiento() {
      this.optionsCat1()
      this.readCat2 = true
      this.readCat3 = true
      this.readCat4 = true
      this.selectedEditarCat1 = null
      this.selectedEditarCat2 = null
      this.selectedEditarCat3 = null
      this.selectedOptionCat1 = null
      this.selectedOptionCat2 = null
      this.selectedOptionCat3 = null
      this.selectedOptionCat4 = null
      this.comentarioNuevoSeguimiento = ''
    },
    editarSeguimiento() {
      const dataEditarSeguimiento = {
        id_seguimiento: this.idSeguimientos,
        id_categoria: this.selectedEditarCat4.value,
        comentario: this.comentarioEditarSeguimiento,
      }
      this.$store.dispatch('seguimientos/editarSeguimiento', dataEditarSeguimiento)
    },
    editarSeguimientoForm(item) {
      this.readCat2 = false
      this.readCat3 = false
      this.readCat4 = false
      this.selectedOptionCat1 = null
      this.selectedOptionCat2 = null
      this.selectedOptionCat3 = null
      this.comentarioEditarSeguimiento = item.comentario
      this.selectedEditarCat1 = { title: item.categoria1.descripcion, value: item.categoria1.id }
      this.selectedEditarCat2 = { title: item.categoria2.descripcion, value: item.categoria2.id }
      this.selectedEditarCat3 = { title: item.categoria3.descripcion, value: item.categoria3.id }
      this.selectedEditarCat4 = { title: item.categoria4.descripcion, value: item.categoria4.id }
      this.idSeguimientos = item.codigo
    },
    nuevoSeguimiento() {
      const dataNuevoSeguimiento = {
        data:
        [{
          id_categoria: this.selectedOptionCat4.value,
          comentario: this.comentarioNuevoSeguimiento,
          id_detalle_alumno: this.id_detalle_alumno,
          id_usuario: this.usuarioLogeadoGeneral,
        }],
      }
      this.$store.dispatch('seguimientos/agregarSeguimiento', dataNuevoSeguimiento)
    },
    nuevoSeguimientoMasivo() {
      const data = []

      this.selectedIdDetalleAlumnos.forEach((value, index) => {
        data.push(
          {
            id_categoria: this.selectedOptionCat4.value,
            comentario: this.comentarioNuevoSeguimiento,
            id_detalle_alumno: value,
            id_usuario: this.usuarioLogeadoGeneral,
          },
        )
      })
      const dataNuevoSeguimiento = {
        data,
      }
      this.$store.dispatch('seguimientos/agregarSeguimiento', dataNuevoSeguimiento)
      this.selectedIdDetalleAlumnos = []
      this.allSelected = false
    },
    optionsCat1() {
      this.$store.dispatch('seguimientos/optionCat1')
    },
    getOptionCat2(value) {
      this.readCat2 = true
      this.readCat3 = true
      this.readCat4 = true
      this.selectedOptionCat2 = null
      this.selectedOptionCat3 = null
      this.selectedOptionCat4 = null
      this.selectedEditarCat2 = null
      this.selectedEditarCat3 = null
      this.selectedEditarCat4 = null
      this.$store.dispatch('seguimientos/optionCat2', value)
    },
    getOptionCat3(value) {
      this.readCat3 = true
      this.readCat4 = true
      this.selectedEditarCat3 = null
      this.selectedEditarCat4 = null
      this.selectedOptionCat3 = null
      this.selectedOptionCat4 = null
      this.$store.dispatch('seguimientos/optionCat3', value)
    },
    getOptionCat4(value) {
      this.readCat4 = true
      this.selectedEditarCat4 = null
      this.selectedOptionCat4 = null
      this.$store.dispatch('seguimientos/optionCat4', value)
    },
    getListadoSeguimientos() {
      const filterData = {
        page: this.page,
        perPage: this.perPage,
        estado: this.selectedEstadoGeneral,
        mis_asignados: this.filter.selectedMisAsignados==true?1:0,
        curso_completado: this.filter.cursoCompletado,
        num_ficha: this.filter.selectedNumeroficha,
        nombre: this.filter.selectedNombre,
        fecha_inicio: this.filter.selectedFechaInicio,
        fecha_cierre: this.filter.selectedFechaCierre,
        rut: this.filter.selectedRut,
        correo: this.filter.selectedCorreo,
        id_holding: this.filter.selectedHolding,
        id_empresa: this.filter.selectedEmpresa,
        id_aplicacion: this.filter.selectedAplication,
      }
      this.$store.dispatch('seguimientos/listadoSeguimientos', this.buildParams(filterData))
    },
    getListadoSeguimientosMasivo() {
      const filterData = {
        page: this.pageMasivo,
        perPage: this.perPageMasivo,
        estado: 'active',
        mis_asignados: this.filterMasivo.selectedMisAsignados==true?1:0,
        num_ficha: this.filterMasivo.numFicha,
        curso_completado: this.filterMasivo.cursoCompletado,
        fecha_inicio: this.filterMasivo.selectedFechaInicio,
        fecha_cierre: this.filterMasivo.selectedFechaCierre,
        id_holding: this.filterMasivo.selectedHolding,
        id_empresa: this.filterMasivo.selectedEmpresa,
      }

      this.$store.dispatch('seguimientos/listadoSeguimientosMasivo', this.buildParams(filterData))
    },
    buildParams(_param){

      return new URLSearchParams(_param);

    },
    listHistorialSeguimientos(item) {
      this.optionsCat1()
      this.id_detalle_alumno = item
      this.$store.dispatch('seguimientos/getInfoAlumno', this.id_detalle_alumno)
      const filterData = {
        page: this.pageHistorialSeguimientos,
        perPage: this.perPageHistorialSeguimientos,
        id_detalle_alumno: item,
      }
      this.$store.dispatch('seguimientos/listadoHistorialSeguimientos', this.buildParams(filterData))
    },
    onPageChange(page) {
      this.page = page
      this.getListadoSeguimientos()
    },
    onPageChangeMasivo(page) {
      this.pageMasivo = page
      this.getListadoSeguimientosMasivo()
    },
    onPageChangeHistorialSeguimientos(page) {
      this.pageHistorialSeguimientos = page
      this.listHistorialSeguimientos(this.id_detalle_alumno)
    },
    onPerPageChange(perPage) {
      this.perPage = perPage
      this.getListadoSeguimientos(this.id_detalle_alumno)
    },
    onPerPageChangeMasivo(perPage) {
      this.perPageMasivo = perPage
      this.getListadoSeguimientosMasivo()
    },
    onPerPageChangeHistorialSeguimientos(perPage) {
      this.perPageHistorialSeguimientos = perPage
      this.listHistorialSeguimientos(this.id_detalle_alumno)
    },
    /* onPerPageChangeHistorialAlumnos(perPage) {
      this.perPageHistorialAlumnos = perPage
      this.listHistorialAlumnos(this.id_detalle_alumno)
    }, */
    toggleAll(checked) {

      this.selectedIdDetalleAlumnos = []
      if (checked) {
        this.listaSeguimientosMasivo.map(item => (
          this.selectedIdDetalleAlumnos.push(item.id_detalle_alumno)
        ))
      } else {
        this.selectedIdDetalleAlumnos = []
      }

    },
    makeToast(variant = null, titulo, mensaje) {
      this.$bvToast.toast(mensaje, {
        // title: `Variant ${variant || 'default'}`,
        title: titulo,
        variant,
        solid: true,
      })
    },
    warningIndicator(item, type) {
      if (!item || type !== 'row' ) return 'table-danger'
      if(item.indicador <= 1) return 'table-success'
      if(item.indicador <= 2 ) return 'table-warning'
      if (item.indicador > 2 ||  item.indicador=="" ) return 'table-danger'
    },
    buscarListadoSeguimientos(filter) {

      if (filter.selectedEstado === true) {
        this.selectedEstadoGeneral = 'active'
      } else {
        this.selectedEstadoGeneral = 'false'
      }
      this.filter.selectedNumeroficha = filter.selectedNumeroficha
      this.filter.cursoCompletado=filter.cursoCompletado
      this.filter.selectedNombre = filter.selectedNombre
      this.filter.selectedFechaInicio = filter.fechaInicio
      this.filter.selectedFechaCierre = filter.fechaCierre
      this.filter.selectedRut = filter.selectedRut
      this.filter.selectedCorreo = filter.selectedCorreo
      this.filter.selectedEmpresa = filter.selectedEmpresa
      this.filter.selectedHolding = filter.selectedHolding
      this.filter.selectedMisAsignados = filter.selectedMisAsignados
      this.getListadoSeguimientos()
    },
    descargarSeguimiento(filter){


      if (filter.selectedEstado === true) {
        this.selectedEstadoGeneral = 'active'
      } else {
        this.selectedEstadoGeneral = 'false'
      }
      this.filter.selectedNumeroficha = filter.selectedNumeroficha
      this.filter.cursoCompletado=filter.cursoCompletado
      this.filter.selectedNombre = filter.selectedNombre
      this.filter.selectedFechaInicio = filter.fechaInicio
      this.filter.selectedFechaCierre = filter.fechaCierre
      this.filter.selectedRut = filter.selectedRut
      this.filter.selectedCorreo = filter.selectedCorreo
      this.filter.selectedEmpresa = filter.selectedEmpresa
      this.filter.selectedHolding = filter.selectedHolding
      this.filter.selectedMisAsignados = filter.selectedMisAsignados
      const filterData = {
        page: this.page,
        perPage: this.perPage,
        estado: this.selectedEstadoGeneral,
        mis_asignados: filter.selectedMisAsignados==true?1:0,
        curso_completado: this.filter.cursoCompletado,
        num_ficha: this.filter.selectedNumeroficha,
        nombre: this.filter.selectedNombre,
        fecha_inicio: this.filter.selectedFechaInicio,
        fecha_cierre: this.filter.selectedFechaCierre,
        rut: this.filter.selectedRut,
        correo: this.filter.selectedCorreo,

        id_holding: this.filter.selectedHolding,
        id_empresa: this.filter.selectedEmpresa,
        id_aplicacion: this.filter.selectedAplication,
      }
      this.$store.dispatch('seguimientos/exportDataSeguimiento', filterData)
    },
    descargarSeguimientoMasivo(filter){
      this.filterMasivo.cursoCompletado=filter.cursoCompletado
      this.filterMasivo.numFicha = filter.selectedNumeroficha
      this.filterMasivo.selectedEmpresa = filter.selectedEmpresa
      this.filterMasivo.selectedHolding = filter.selectedHolding
      this.filterMasivo.selectedFechaInicio = filter.fechaInicio
      this.filterMasivo.selectedFechaCierre = filter.fechaCierre
      this.filterMasivo.selectedMisAsignados=filter.selectedMisAsignados;
      const filterData = {
        page: this.pageMasivo,
        perPage: this.perPageMasivo,
        estado: 'active',
        mis_asignados: filter.selectedMisAsignados==true?1:0,
        num_ficha: this.filterMasivo.numFicha,
        curso_completado: this.filterMasivo.cursoCompletado,
        fecha_inicio: this.filterMasivo.selectedFechaInicio,
        fecha_cierre: this.filterMasivo.selectedFechaCierre,
        id_holding: this.filterMasivo.selectedHolding,
        id_empresa: this.filterMasivo.selectedEmpresa,
      }
      this.$store.dispatch('seguimientos/exportDataSeguimientoMasivo', filterData)
    },
    buscarListadoSeguimientosMasivos(filter) {

      this.filterMasivo.cursoCompletado=filter.cursoCompletado
      this.filterMasivo.numFicha = filter.selectedNumeroficha
      this.filterMasivo.selectedMisAsignados=filter.selectedMisAsignados
      this.filterMasivo.selectedEmpresa = filter.selectedEmpresa
      this.filterMasivo.selectedHolding = filter.selectedHolding
      this.filterMasivo.selectedFechaInicio = filter.fechaInicio
      this.filterMasivo.selectedFechaCierre = filter.fechaCierre
      this.getListadoSeguimientosMasivo()
    },

  },
}
</script>

<style>

</style>
