<template>
    <b-card>
        <b-form @submit.prevent>
            <app-collapse>
                <app-collapse-item title="Búsqueda">
                    <b-row>
                        <b-col md="12">
                            <b-form-group
                            >
                                <h5 class="font-weight-bold">
                                    Por Avance
                                </h5>
                                <div class="demo-inline-spacing curso">
                                    <b-form-radio
                                            v-model="filter.cursoCompletado"
                                            name="curso_completado"
                                            value="3"
                                            class="custom-control-primary"
                                    >
                                        Todos
                                    </b-form-radio>
                                    <b-form-radio
                                            v-model="filter.cursoCompletado"
                                            name="curso_completado"
                                            value="1"
                                            class="custom-control-primary"
                                    >
                                        Finalizado
                                    </b-form-radio>
                                    <b-form-radio
                                            v-model="filter.cursoCompletado"
                                            name="curso_completado"
                                            value="2"
                                            class="custom-control-primary"
                                    >
                                        Sin Finalizar
                                    </b-form-radio>

                                </div>

                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="12">
                        </b-col>
                        <b-col md="3" class="d-flex align-items-center mb-1 mb-md-0 pt-1">
                            <b-form-checkbox
                                    v-model="filter.selectedMisAsignados"
                                    checked="true"
                                    name="check-button"
                                    switch
                                    inline
                            >
                                Mis Asignados
                            </b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row class="mt-1">
                        <b-col md="4" class="">
                            <b-form-group>
                                <h5 class="font-weight-bold">
                                    Holding
                                </h5>

                                <autocomplete-infinity
                                        url="contact-center/list-holding"
                                        placeholder="Seleccione Holding"
                                        @item-selected="selectHolding"
                                        label="nombre_holding"
                                        id="holding"
                                        :selectedEmpresa="filter.selectedHolding"
                                        itemsPerPage="10"
                                ></autocomplete-infinity>

                            </b-form-group>
                        </b-col>
                        <b-col md="4">
                            <b-form-group>
                                <h5 class="font-weight-bold">
                                    Empresa
                                </h5>

                                <autocomplete-infinity
                                        url="contact-center/list-empresa"
                                        placeholder="Seleccione Empresa"
                                        @item-selected="selectEmpresa"
                                        label="razon_social_empresa"
                                        id="holding"
                                        :selectedEmpresa="filter.selectedEmpresa"
                                        itemsPerPage="10"
                                ></autocomplete-infinity>

                            </b-form-group>
                        </b-col>
                        <b-col md="4">
                            <b-form-group>
                                <h5 class="font-weight-bold">
                                    Número Ficha
                                </h5>

                                <b-form-input
                                        id="numFicha"
                                        v-model="filter.selectedNumeroficha"
                                        placeholder="Escriba Número de Ficha"
                                />

                            </b-form-group>
                        </b-col>
                        <b-col md="4">
                            <b-form-group>
                                <h5 class="font-weight-bold">
                                    Fecha Inicio
                                </h5>
                                <div class="input-group">
                                    <flat-pickr
                                            v-model="filter.fechaInicio"
                                            class="form-control"
                                            placeholder="Click para seleccionar..."
                                            :config="config"
                                            reset-button
                                    />
                                    <div class="input-group-append">
                                        <button class="btn-clear" type="button" title="Limpiar" data-clear>
                                            <feather-icon
                                                    icon="XIcon"
                                            />
                                        </button>
                                    </div>
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col md="4">
                            <b-form-group>
                                <h5 class="font-weight-bold">
                                    Fecha Cierre
                                </h5>
                                <div class="input-group">
                                    <flat-pickr
                                            v-model="filter.fechaCierre"
                                            class="form-control"
                                            placeholder="Click para seleccionar..."
                                            :config="config"
                                            reset-button
                                    />
                                    <div class="input-group-append">
                                        <button class="btn-clear" type="button" title="Limpiar" data-clear>
                                            <feather-icon
                                                    icon="XIcon"
                                            />
                                        </button>
                                    </div>
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col md="6"/>
                        <b-col md="12">
                            <div class="text-right">
                                <b-button
                                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                        variant="outline-primary"
                                        type="submit"
                                        class="mr-1"
                                        @click="$emit('descargar', filter)"
                                >

                                    <feather-icon
                                            icon="DownloadIcon"
                                            class="mr-50"
                                    />
                                    <span class="align-middle">Descargar</span>
                                </b-button>
                                <b-button
                                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                        variant="outline-primary"
                                        type="submit"
                                        @click="$emit('buscar', filter)"
                                >

                                    <feather-icon
                                            icon="SearchIcon"
                                            class="mr-50"
                                    />
                                    <span class="align-middle">Buscar</span>
                                </b-button>
                                <b-button
                                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                        class="d-none"
                                        variant="outline-primary"
                                        type="submit"
                                >

                                    <feather-icon
                                            icon="RefreshCcwIcon"
                                            class="mr-50"
                                    />
                                    <span class="align-middle">Limpiar</span>
                                </b-button>
                            </div>
                        </b-col>
                    </b-row>
                </app-collapse-item>
            </app-collapse>
        </b-form>
    </b-card>
</template>

<script>
    import {
        BRow, BCol, BFormGroup, BFormInput, BFormCheckboxGroup, BForm, BButton, BIcon, BootstrapVue, BootstrapVueIcons,
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
    import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
    import store from '@/store'
    import Vue from 'vue'

    Vue.use(BootstrapVue)
    Vue.use(BootstrapVueIcons)
    export default {
        name: 'FiltrosSeguimientosMasivos',
        components: {
            AppCollapse,
            BRow,
            BCol,
            BFormGroup,
            BFormInput,
            BForm,
            BButton,
            AppCollapseItem,
            BFormCheckboxGroup,
            'b-icon': BIcon,
            BootstrapVue,
            BootstrapVueIcons,

        },
        directives: {
            Ripple,
        },
        props: [''],
        data() {
            return {
                optionDias: null,
                admin: false,
                usuarioLogeado: null,
                idUsuarioLogeado: null,
                filter: {
                    cursoCompletado: 3,
                    selectedHolding: "",
                    selectedEmpresa: "",
                    selectedNumeroficha: '',
                    selectedMisAsignados: true,
                    fechaInicio: "",
                    fechaCierre: ""
                },
                optionsMisAsignados: [
                    {text: 'Mis Asignados', value: true},
                ],
            }
        },
        computed: {
            config() {
                return {
                    locale: require('flatpickr/dist/l10n/es.js').default.es,
                    dateFormat: 'd-m-Y',
                    wrap: true,
                }
            },
            /* validarAdmin() {
              this.usuarioLogeado = store.state.auth.user
              this.idUsuarioLogeado = this.usuarioLogeado.id_usuario
              //console.log('logeadoMasivo ', this.idUsuarioLogeado);
              //this.usuarioLogeado.fk_id_perfil == 27 || this.usuarioLogeado.fk_id_perfil == 1 ? this.admin = true : this.admin = false
              return ''
              //return this.admin
            }, */
            optionDia() {
                this.listaDias = [{
                    value: null,
                    text: 'Puede seleccionar',
                }]
                this.optionDias = this.$store.state.reporteSeguimientos.optionDias
                this.optionDias.map(item => {
                    this.listaDias.push({
                        text: item.description,
                        value: item.id_dias,
                    })
                })
                return this.listaDias
            },
        },
        methods: {
            selectHolding(holding) {
                this.filter.selectedHolding = holding != null ? holding.id_holding : null
            },
            selectEmpresa(empresa) {
                this.filter.selectedEmpresa = empresa != null ? empresa.id_empresa : null
            },
            selectTeletutor(teletutor) {
                this.filter.selectedTeletutor = teletutor != null ? teletutor.id_usuario : null
            },
            selectedDias(dias) {
                this.filter.selectedDias = dias != null ? dias.id_dias : null
            },

        },
    }
</script>

<style scoped lang="scss">
    @import '@core/scss/vue/libs/vue-flatpicker.scss';

    .demo-inline-spacing.curso .custom-radio {
        margin-top: 0.5rem !important;
    }

    .btn-clear {
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #d8d6de;
        border-radius: 0px 3px 3px 0px !important;
        color: #6e6b7b;
    }
</style>
