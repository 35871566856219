<template>
  <b-card>
    <b-form @submit.prevent>
      <app-collapse>
        <app-collapse-item title="Búsqueda">
          <b-row>
            <b-col md="12">
              <b-form-group
              >
                <h5 class="font-weight-bold">
                  Por Avance
                </h5>
                <div class="demo-inline-spacing curso">
                  <b-form-radio
                      v-model="filter.cursoCompletado"
                      name="curso_completado"
                      value="3"
                      class="custom-control-primary"
                  >
                    Todos
                  </b-form-radio>
                  <b-form-radio
                      v-model="filter.cursoCompletado"
                      name="curso_completado"
                      value="1"
                      class="custom-control-primary"
                  >
                    Finalizado
                  </b-form-radio>
                  <b-form-radio
                      v-model="filter.cursoCompletado"
                      name="curso_completado"
                      value="2"
                      class="custom-control-primary"
                  >
                    Sin Finalizar
                  </b-form-radio>

                </div>

              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
            </b-col>
            <b-col md="3">
              <!-- checkbox button  -->
              <b-form-checkbox
                  v-model="filter.selectedEstado"
                  checked="true"
                  name="check-button"
                  switch
                  inline
              >
                Activo
              </b-form-checkbox>
            </b-col>
            <b-col md="3">
              <b-form-checkbox
                  v-model="filter.selectedMisAsignados"
                  checked="true"
                  name="check-button"
                  switch
                  inline
              >
                Mis Asignados
              </b-form-checkbox>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="4" class="pt-1">
              <b-form-group>
                <h5 class="font-weight-bold">
                  Holding
                </h5>

                <autocomplete-infinity
                    url="contact-center/list-holding"
                    placeholder="Seleccione Holding"
                    @item-selected="selectHolding"
                    label="nombre_holding"
                    id="holding"
                    :selectedEmpresa="filter.selectedHolding"
                    itemsPerPage="10"
                ></autocomplete-infinity>

              </b-form-group>
            </b-col>
            <b-col md="4" class="pt-1">
              <b-form-group>
                <h5 class="font-weight-bold">
                  Empresa
                </h5>

                <autocomplete-infinity
                    url="contact-center/list-empresa"
                    placeholder="Seleccione Empresa"
                    @item-selected="selectEmpresa"
                    label="razon_social_empresa"
                    id="holding"
                    :selectedEmpresa="filter.selectedEmpresa"
                    itemsPerPage="10"
                ></autocomplete-infinity>

              </b-form-group>
            </b-col>
            <b-col md="4" class="pt-1">
              <b-form-group>
                <h5 class="font-weight-bold">
                  Número Ficha
                </h5>

                <b-form-input
                    id="numFicha"
                    v-model="filter.selectedNumeroficha"
                    placeholder="Escriba Número de Ficha"
                />

              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <h5 class="font-weight-bold">
                  Nombre o Apellido
                </h5>

                <b-form-input
                    id="rut"
                    v-model="filter.selectedNombre"
                    placeholder="Escriba Nombre"
                />

              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <h5 class="font-weight-bold">
                  RUT
                </h5>

                <b-form-input
                    id="rut"
                    v-model="filter.selectedRut"
                    placeholder="Escriba RUT"
                />

              </b-form-group>

            </b-col>
            <b-col md="4">
              <b-form-group>
                <h5 class="font-weight-bold">
                  Correo
                </h5>

                <b-form-input
                    id="correo"
                    v-model="filter.selectedCorreo"
                    placeholder="Escriba Correo"
                />

              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <h5 class="font-weight-bold">
                  Fecha Inicio
                </h5>
                <div class="input-group">
                  <flat-pickr
                      v-model="filter.fechaInicio"
                      class="form-control"
                      placeholder="Click para seleccionar..."
                      :config="config"
                      reset-button
                  />
                  <div class="input-group-append">
                    <button class="btn-clear" type="button" title="Limpiar" data-clear>
                      <feather-icon
                          icon="XIcon"
                      />
                    </button>
                  </div>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <h5 class="font-weight-bold">
                  Fecha Cierre
                </h5>
                <div class="input-group">
                  <flat-pickr
                      v-model="filter.fechaCierre"
                      class="form-control"
                      placeholder="Click para seleccionar..."
                      :config="config"
                      reset-button
                  />
                  <div class="input-group-append">
                    <button class="btn-clear" type="button" title="Limpiar" data-clear>
                      <feather-icon
                          icon="XIcon"
                      />
                    </button>
                  </div>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="6" />
            <b-col>
              <div class="text-right">
                <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        type="submit"
                        class="mr-1"
                        @click="$emit('descargar', filter)"
                >

                  <feather-icon
                          icon="DownloadIcon"
                          class="mr-50"
                  />
                  <span class="align-middle">Descargar</span>
                </b-button>
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                    @click="$emit('buscar', filter)"
                >

                  <feather-icon
                      icon="SearchIcon"
                      class="mr-50"
                  />
                  <span class="align-middle">Buscar</span>
                </b-button>
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    class="d-none"
                    variant="outline-primary"
                    type="submit"
                >

                  <feather-icon
                      icon="RefreshCcwIcon"
                      class="mr-50"
                  />
                  <span class="align-middle">Limpiar</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </app-collapse-item>
      </app-collapse>
    </b-form>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BFormCheckboxGroup, BForm, BButton, BIcon, BootstrapVue, BootstrapVueIcons
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import store from "@/store";
import Vue from "vue";

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
export default {
  name: "FiltrosSeguimientos",
  components: {
    AppCollapse,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    AppCollapseItem,
    BFormCheckboxGroup,
    "b-icon": BIcon,
    BootstrapVue,
    BootstrapVueIcons

  },
  directives: {
    Ripple
  },
  props: [""],
  data() {
    return {
      optionDias: null,
      admin: false,
      usuarioLogeado: null,
      listaDias: [{
        value: null,
        text: "Puede seleccionar"
      }],
      filter: {
        cursoCompletado: 3,
        selectedHolding: "",
        selectedEmpresa: "",
        selectedPlataforma: "",
        selectedNumeroficha: "",
        selectedCorreo: "",
        selectedRut: "",
        selectedNombre: "",
        selectedEstado: true,
        selectedMisAsignados: true,
        fechaInicio: "",
        fechaCierre: "",
        aplicación: "",
        plataforma: ""
      },
      optionsActivo: [
        { text: "Activo", value: true }
      ],
      optionsMisAsignados: [
        { text: "Mis Asignados", value: true }
      ]
    };
  },
  computed: {
    config() {
      return {
        locale: require("flatpickr/dist/l10n/es.js").default.es,
        dateFormat: "Y-m-d",
        wrap: true
      };
    },
    /* validarAdmin() {
      this.usuarioLogeado = store.state.auth.user
      //this.usuarioLogeado.fk_id_perfil == 27 || this.usuarioLogeado.fk_id_perfil == 1 ? this.admin = true : this.admin = false

      return this.admin
    }, */
    optionDia() {
      this.listaDias = [{
        value: null,
        text: "Puede seleccionar"
      }];
      this.optionDias = this.$store.state.reporteSeguimientos.optionDias;
      this.optionDias.map(item => {
        this.listaDias.push({
          text: item.description,
          value: item.id_dias
        });
      });
      return this.listaDias;
    }
  },
  methods: {
    selectHolding(holding) {
      this.filter.selectedHolding = holding != null ? holding.id_holding : "";
    },
    selectEmpresa(empresa) {
      this.filter.selectedEmpresa = empresa != null ? empresa.id_empresa : "";
    },
    selectTeletutor(teletutor) {
      this.filter.selectedTeletutor = teletutor != null ? teletutor.id_usuario : null;
    },
  }
};
</script>

<style scoped lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.demo-inline-spacing.curso .custom-radio {
  margin-top: 0.5rem !important;
}

.btn-clear {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0px 3px 3px 0px !important;
  color: #6e6b7b;
}
</style>
